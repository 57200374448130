// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-chatbox-js": () => import("./../../../src/pages/chatbox.js" /* webpackChunkName: "component---src-pages-chatbox-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-new-business-js": () => import("./../../../src/pages/contact/new-business.js" /* webpackChunkName: "component---src-pages-contact-new-business-js" */),
  "component---src-pages-culture-js": () => import("./../../../src/pages/culture.js" /* webpackChunkName: "component---src-pages-culture-js" */),
  "component---src-pages-digital-marketing-js": () => import("./../../../src/pages/digital-marketing.js" /* webpackChunkName: "component---src-pages-digital-marketing-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-templates-chatbox-js": () => import("./../../../src/templates/chatbox.js" /* webpackChunkName: "component---src-templates-chatbox-js" */),
  "component---src-templates-legals-js": () => import("./../../../src/templates/legals.js" /* webpackChunkName: "component---src-templates-legals-js" */),
  "component---src-templates-new-service-js": () => import("./../../../src/templates/newService.js" /* webpackChunkName: "component---src-templates-new-service-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */),
  "component---src-templates-work-js": () => import("./../../../src/templates/work.js" /* webpackChunkName: "component---src-templates-work-js" */)
}

